window.$ = window.jQuery = require('jquery');
window.Popper = require('popper.js');
require('bootstrap'); 

$(document).ready(function () {
    $('.btn').click(function () {
        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    });
});

// Transaction_id management with iframe for adv
const paramsQuery = window.location.search;
const urlParams = new URLSearchParams(paramsQuery);
const transactionID = urlParams.get('transaction_id');
// transactionID non used but available on var "transactionID"

function addIframe() { // To rachat-deprets.com ONLY

    $('#qazle').addClass('show');
    $('#qazle').removeClass('hide');

    // Le formulaire ne fonctionne pas si appelé après validation cookies... pas de solution tech à vue
    // var FormIframe = document.createElement("iframe");
    // FormIframe.setAttribute("src", "https://form.qazle.com/form-rac-v1?creationToken=5d5a54ab59d070.50168399&amp;originToken=e91fbaf7-9e24-43f3-983c-16e5b0d433c3&amp;backgroundColor=%23ffffff&amp;theme=orange");
    // FormIframe.setAttribute("style", "border:none;overflow: hidden;width:100%;height:100%");
    // FormIframe.setAttribute("id", "qazle");
    // FormIframe.setAttribute("frameborder", "0");

    // var parentDiv = document.getElementsByClassName("form");
    // parentDiv[0].appendChild(FormIframe);


    // var w = window;
    // w.addEventListener('message', function(e) {
    // var d = JSON.parse(e.data);
    // if (d.event === 'system')
    // {w.document.getElementById('qazle').setAttribute('height', d.heightForm);}});

    // w.addEventListener('message', function(e) {
    // var d = JSON.parse(e.data);
    // if (d.event === 'afterSubmit' && d.extraData['response']['critereValid'] === true) {
    // var div = document.createElement('div');
    // div.innerHTML = '<img src="https://affdjz.go2cloud.org/aff_l?offer_id=4110" width="1" height="1" />';
    // div.style.cssText = 'display:none;';
    // w.document.body.appendChild(div);
    // }});
}

// RGPD Management
const days = 90;// days time to save cookie choice
const cookieSaved = getCookie("rgpd_save");
const cookieConsent = getCookie("rgpd_consent");
const cookiePartial = getCookie("rgpd_partial");

// Config cookies expiration date
var date = new Date();
date.setTime(date.getTime() + (days*24*60*60*1000));
var expires = "; expires=" + date.toUTCString();

// Init RGPD in second time to wait all loading
window.onload = initDoc;

function initDoc() {

    var acceptRgpd = document.querySelectorAll(".accept-all-rgpd");
    var denyRgpd = document.querySelectorAll(".deny-all-rgpd");
    var partialRgpd = document.getElementsByClassName("cookie-valid-partial")[0];
    //var denyRgpdSub = document.getElementById("deny-all-rgpd-sub");
    var closeRgpd = document.getElementsByClassName("cross-rgpd")[0];
    var detailsRgpd = document.getElementsByClassName("rgpd-details-link")[0];
    var closeDetailsRgpd = document.querySelectorAll(".rgpd-details-close");
    var showEssentialTableDetails = document.getElementsByClassName("show-essentials")[0];
    var hideEssentialTableDetails = document.getElementsByClassName("hide-essentials")[0];
    var showStatistiquesTableDetails = document.getElementsByClassName("show-statistiques")[0];
    var hideStatistiquesTableDetails = document.getElementsByClassName("hide-statistiques")[0];
    var showMarketingTableDetails = document.getElementsByClassName("show-marketing")[0];
    var hideMarketingTableDetails = document.getElementsByClassName("hide-marketing")[0];

    acceptRgpd.forEach(function(acceptButton) {
        acceptButton.onclick = RGPDaccepted;
    });

    denyRgpd.forEach(function(denyButton) {
        denyButton.onclick = RGPDdenied;
    });

    closeDetailsRgpd.forEach(function(closeElement) {
        closeElement.onclick = RGPDcloseDetails;
    })

    partialRgpd.onclick = RGPDpartial;
    //closeRgpd.onclick = RGPDdenied;
    detailsRgpd.onclick = RGPDshowDetails;
    showEssentialTableDetails.onclick = showEssentials;
    hideEssentialTableDetails.onclick = hideEssentials;
    showStatistiquesTableDetails.onclick = showStatistiques;
    hideStatistiquesTableDetails.onclick = hideStatistiques;
    showMarketingTableDetails.onclick = showMarketing;
    hideMarketingTableDetails.onclick = hideMarketing;

    if(cookieSaved != "true") {
        initRgpd();
    }

    if(cookieConsent == "true") {
        RGPDaccepted();
    }

    if(cookieConsent == "false") {
        RGPDdenied();
    }

    if(cookieConsent == "partial") {
        RGPDpartial();
    }

    function initRgpd() {
        $('.drawer-rgpd').removeClass('hidden');
        $('.modal-rgpd').removeClass('hidden');
        $('.drawer-rgpd').addClass('open');
        $('.modal-rgpd').addClass('open');

        document.body.classList.add("no-scroll");
    }

    $('.cross-rgpd span,.cookie-valid-partial, button, .deny-all-rgpd').click( function () {
        $('.modal-rgpd').removeClass('open');
        $('.modal-rgpd').addClass('hidden');
        $('.drawer-rgpd').removeClass('open');
        $('.drawer-rgpd').addClass('hidden');

        // Close details modal too
        $('.modal-rgpd-details').removeClass('open');
        $('.modal-rgpd-details').addClass('hidden');
        $('.drawer-rgpd-details').removeClass('open');
        $('.drawer-rgpd-details').addClass('hidden');

        document.body.classList.remove("no-scroll");
    })

    function RGPDshowDetails() {
        $('.modal-rgpd').removeClass('open');
        $('.modal-rgpd').addClass('hidden');
        $('.drawer-rgpd').removeClass('open');
        $('.drawer-rgpd').addClass('hidden');

        $('.drawer-rgpd-details').removeClass('hidden');
        $('.modal-rgpd-details').removeClass('hidden');
        $('.drawer-rgpd-details').addClass('open');
        $('.modal-rgpd-details').addClass('open');
    }

    function RGPDcloseDetails() {
        $('.modal-rgpd').removeClass('hidden');
        $('.modal-rgpd').addClass('open');
        $('.drawer-rgpd').removeClass('hidden');
        $('.drawer-rgpd').addClass('open');

        $('.drawer-rgpd-details').removeClass('open');
        $('.modal-rgpd-details').removeClass('open');
        $('.drawer-rgpd-details').addClass('hidden');
        $('.modal-rgpd-details').addClass('hidden');

        document.body.classList.remove("no-scroll");
    }

    function RGPDaccepted() {
    // All RGPD accepted, we list all

        addEssentials();
        addStatistiques();
        addMarketing();

        // Add cookie to save choice if saving checked
        var saveCheck = document.getElementById("save-rgpd");
    
        if(saveCheck.checked == true) {
            document.cookie = "rgpd_save="+ saveCheck.checked + expires;
            document.cookie = "rgpd_consent=true" + expires;
        }

    }

    function RGPDdenied() {

        addEssentials();

         // Add cookie to save choice if checked
         var saveCheck = document.getElementById("save-rgpd");
        
         if(saveCheck.checked == true) {
             document.cookie = "rgpd_save="+ saveCheck.checked + expires;
             document.cookie = "rgpd_consent=false" + expires;
         }
    }

    function RGPDpartial() {
    // Some RGPD accepted

        addEssentials();
        
        var statistiquesCheck = document.getElementById("stats");
        var marketingCheck = document.getElementById("marketing");
        
        if(statistiquesCheck.checked == true 
            || cookiePartial == "statistique"
            || cookiePartial == "both") {
            addStatistiques();
        }

        if(marketingCheck.checked == true
            || cookiePartial == "marketing"
            || cookiePartial == "both") {
            addMarketing();
        }

        // Add cookie to save choice if saving checked
        var saveCheck = document.getElementById("save-rgpd");
    
        if(saveCheck.checked == true) {

            document.cookie = "rgpd_save="+ saveCheck.checked + expires;
            document.cookie = "rgpd_consent=partial" + expires;

            if(statistiquesCheck.checked == true) {
                document.cookie = "rgpd_partial=statistiques" + expires;
            }

            if(marketingCheck.checked == true) {
                document.cookie = "rgpd_partial=marketing" + expires;
            }

            if((marketingCheck.checked == true) && (statistiquesCheck.checked == true)) {
                document.cookie = "rgpd_partial=both" + expires;
            }
        }
    }

    // We create a sandox iframe to limit foreign iframe cookie access
    function falseIframe() {
       
    }

    function addEssentials() {
        // Add here your essentials RGPD to inject
        addIframe();
    }

    function addMarketing() {
        // Add here your marketing RGPD to inject
    }

    function addStatistiques() {
        // Add here your Statistiques RGPD to inject
    }

    //modal management
    $('.ml').click( function () {
       $('.modal').addClass('open');
       $('.modal').removeClass('hidden');
       $('.legals').addClass('open');
       $('.legals').removeClass('hidden');
       $('.contacts').addClass('hidden');
       $('.contacts').removeClass('open');
       $('.drawer').addClass('open');
       $('.drawer').removeClass('hidden');
    });

    $('.contact').click( function () {
        $('.modal').addClass('open');
        $('.modal').removeClass('hidden');
        $('.contacts').addClass('open');
        $('.contacts').removeClass('hidden');
        $('.legals').addClass('hidden');
        $('.legals').removeClass('open');
        $('.drawer').addClass('open');
        $('.drawer').removeClass('hidden');
     });
 

    $('.cross span, .drawer').click( function () {
        $('.modal').removeClass('open');
        $('.modal').addClass('hidden');
        $('.drawer').removeClass('open');
        $('.drawer').addClass('hidden');
        $('.contacts').removeClass('open');
        $('.legals').removeClass('open');

        $('.partner-modal').removeClass('open');
        $('.partner-modal').addClass('hidden');
    })

    function showEssentials() {
        $('#essentials-table').addClass('open');
        $('#essentials-table').removeClass('hidden');

        $('.hide-essentials').removeClass("hidden");
        $('.hide-essentials').addClass("open");
        
        $('.show-essentials').removeClass("open");
        $('.show-essentials').addClass("hidden");
    }

    function hideEssentials() {
        $('#essentials-table').removeClass('open');
        $('#essentials-table').addClass('hidden');

        $('.hide-essentials').removeClass("open");
        $('.hide-essentials').addClass("hidden");
        
        $('.show-essentials').removeClass("hidden");
        $('.show-essentials').addClass("open");
    }

    function showStatistiques() {
        $('#statistiques-table').addClass('open');
        $('#statistiques-table').removeClass('hidden');

        $('.hide-statistiques').removeClass("hidden");
        $('.hide-statistiques').addClass("open");
        
        $('.show-statistiques').removeClass("open");
        $('.show-statistiques').addClass("hidden");
    }

    function hideStatistiques() {
        $('#statistiques-table').removeClass('open');
        $('#statistiques-table').addClass('hidden');

        $('.hide-statistiques').removeClass("open");
        $('.hide-statistiques').addClass("hidden");
        
        $('.show-statistiques').removeClass("hidden");
        $('.show-statistiques').addClass("open");
    }

    function showMarketing() {
        $('#marketing-table').addClass('open');
        $('#marketing-table').removeClass('hidden');

        $('.hide-marketing').removeClass("hidden");
        $('.hide-marketing').addClass("open");
        
        $('.show-marketing').removeClass("open");
        $('.show-marketing').addClass("hidden");
    }

    function hideMarketing() {
        $('#marketing-table').removeClass('open');
        $('#marketing-table').addClass('hidden');

        $('.hide-marketing').removeClass("open");
        $('.hide-marketing').addClass("hidden");
        
        $('.show-marketing').removeClass("hidden");
        $('.show-marketing').addClass("open");
    }  
} // end of RGPD Management

 // To get cookies name
 function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}

// Petit fonction fade in native
function fadeIn(el, time) {
    el.style.opacity = 0;
  
    var last = +new Date();
    var tick = function() {
      el.style.opacity = +el.style.opacity + (new Date() - last) / time;
      last = +new Date();
  
      if (+el.style.opacity < 1) {
        (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
      }
    };
  
    tick();
}

// gestion de la modal mentions 
$(".modal-mentions-listener").click(function() {
    $(".drawer-modal-mentions").fadeIn("slow");
    $(".modal-mentions").fadeIn("slow");
})

$(".drawer-modal-mentions").click(function() {
    $(".drawer-modal-mentions").fadeOut("slow");
    $(".modal-mentions").fadeOut("slow");
})

// Fin gestion modal mentions